import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../style";

const Slider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [slides.length]);

  return (
    <div className="mt-20 relative overflow-hidden">
      <div className="flex items-center justify-center h-64 sm:h-screen relative">
        {slides.map((slide, index) => (
          <img
            key={index}
            src={slide.image}
            alt={`Slide ${index}`}
            className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
              index === currentIndex ? "opacity-90" : "opacity-0"
            }`}
            style={{
              objectPosition: "center top",
              maxHeight: "100%",
              maxWidth: "100%",
              filter: "brightness(30%)",
            }}
          />
        ))}
        <div className="absolute font-poppins left-4 sm:left-20 top-1/4 sm:top-1/2 transform sm:-translate-y-1/2">
          <h2 className="text-2xl sm:text-6xl text-white">
            {slides[currentIndex].title} <br />
            <span className="text-white">
              {slides[currentIndex].highlight}
            </span>{" "}
            <br />
            {slides[currentIndex].subtitle}
          </h2>
          <p className="text-xl sm:text-2xl text-white pt-5">
            {slides[currentIndex].description}
          </p>
          <Link to="/Contact">
            <button
              type="button"
              className={`py-4 px-6  mt-10 font-poppins font-medium text-[18px] text-white hover:bg-red-900 bg-black rounded-[10px] outline-none ${styles}`}
            >
              Get In Touch
            </button>
          </Link>
        </div>
      </div>
      {/* <div className="absolute inset-0 bg-black opacity-50"></div> */}
    </div>
  );
};

export default Slider;
