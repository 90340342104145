import React from "react";
import styles from "../style";
import { homeimage1, homeimage2, homeimage3, homeimage4 } from "../assets";
const About = () => {
  return (
    <>
      <div className={`${styles.flexCenter} relative mt-5 pt-10 sm:py-13 py-4`}>
        <div className="w-full h-96 sm:h-[32rem] overflow-hidden relative">
          <img
            src={homeimage3}
            alt="Image"
            className="w-full h-full object-cover object-top"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        <div className="absolute inset-0 flex flex-col justify-center text-white">
          <h2
            className={`text-[40px] pl-10 font-poppins leading-[24px] pt-30 mt-20 font-bold mb-4 ml-6 text-white`}
          >
            About Us
          </h2>
          <p className="text-[18px] pl-10 ml-6 sm:block hidden">
            Custom-made Activewear & Workout clothes Manufacturer,
            <br />
            Exporter, & supplier from Sialkot, Pakistan.
          </p>
        </div>
      </div>
      {/* line added */}
      <div className="mt-6 text-center pb-9">
        <h2 className="text-2xl font-poopins sm:text-4xl font-bold text-black">
          Our Vision
        </h2>
        <p className="text-base  text-black font-poopins pl-7 pr-7 ml-5 mr-5 text-[18px] sm:text-[28px]  leading-[20px] sm:leading-[40px] mt-2">
          SuzumeSports pioneers the creation of performance-driven custom
          sportswear and activewear, serving as a trusted manufacturer and
          exporter committed to elevating athletes' performance worldwide.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center justify-center pb-4">
        <div className="md:w-1/2">
          <h1 className="font-poppins pl-4 text-4xl font-bold mb-4 text-black ml-4">
            About Us
          </h1>
          <p className="font-poppins p-5 text-[10px] sm:text-[16px] text-black text-justify mb-6 m-4">
            We are dedicated to continuous improvement, aiming to deliver
            premium quality products and exceptional customer service. Our goal
            is to achieve 100% customer satisfaction, solidifying our position
            as one of the leading manufacturers, suppliers, and exporters of
            sportswear, activewear, and fitness apparel. Strict Quality Control,
            100% Satisfaction Guaranteed. Highly Professional Staff, Accurate
            Testing Processes. Unrivalled Workmanship, Professional and
            Qualified.
          </p>
        </div>
        <div className="md:w-1/2 w-full">
          <img
            src={homeimage2}
            alt="Image"
            className="md:max-w-full mt-4 rounded-lg shadow-2xl"
          />
        </div>
      </div>
      {/* //why */}

      <h1 className="text-center p-4 font-poppins text-4xl font-bold mt-4 pt-5 text-black ml-4">
        Why choose SUZUMESPORTS?
      </h1>

      <div className="flex flex-col mb-4 md:flex-row  items-center justify-center ">
        <div className="md:w-1/2 w-full">
          <img
            src={homeimage4}
            alt="Image"
            className="md:max-w-full mt-4 rounded-lg shadow-2xl "
          />
        </div>
        <div className="md:w-1/2">
          <p className="font-poppins p-5 text-[10px] sm:text-[16px] text-justify text-black mb-4 m-4 pt-4">
           
            <strong>Customer Satisfaction:</strong> Our goal is to achieve 100%
            customer satisfaction, solidifying our position as one of the
            leading manufacturers, suppliers, and exporters of sportswear,
            activewear, and fitness apparel.
            <br />
            <strong>Quality Control:</strong> Strict quality control, 100%
            satisfaction guaranteed.
            <br />
            <strong>Professional Staff:</strong> Highly professional staff with
            accurate testing processes.
            <br />
            <strong>Unrivalled Workmanship:</strong> Professional and qualified
            craftsmanship.
            <br />
            <strong>Innovative Designs:</strong> Trendy designs to keep you
            ahead in style and performance.
            <br />
            <strong>Eco-friendly Practices:</strong> Sustainable and
            eco-friendly practices in our manufacturing processes.
            <br />
            <strong>Customization Options:</strong> Available to meet specific
            client needs.
            <br />
            <strong>Fast Shipping:</strong> Fast and reliable shipping to ensure
            timely delivery of your orders.
            <br />
            <strong>Competitive Pricing:</strong> Competitive pricing without
            compromising on quality.
            <br />
            <strong>After-Sales Support:</strong> Strong after-sales support to
            assist you with any post-purchase inquiries or issues.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
