import React from "react";
import styles from "../style";
import { ProductsBox, casualwearData } from "../constants";
import {
 homeimage3
} from "../assets";
const Casualwear = () => {
  return (
    <>
      <div className={`${styles.flexCenter} relative mt-5 pt-10 sm:py-13 py-4`}>
        <div className="w-full h-96 sm:h-[32rem] overflow-hidden relative">
          <img
            src={homeimage3}
            alt="Image"
            className="w-full h-full object-cover object-top"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="absolute inset-0 flex flex-col justify-center text-white">
          <h2
            className={`text-[40px] pl-10 font-poppins leading-[24px] pt-30 mt-20 font-bold mb-4 ml-6 text-white`}
          >
            Casualwear
          </h2>
          <p className="text-[18px] pl-10 ml-6 sm:block hidden">
            Customizable hoodies, tracksuits, and jackets Manufacturer, <br />
            Exporter, & distributor from Sialkot, Pakistan.
          </p>
        </div>
      </div>

      {/* line added */}
      <div className="mt-6 text-center pb-9">
        <h2 className="text-2xl font-poopins sm:text-4xl font-bold text-black">
          Why Us?
        </h2>
        <p className="text-base  text-black font-poopins pl-7 pr-7 ml-5 mr-5 text-[18px] sm:text-[28px]  leading-[20px] sm:leading-[40px] mt-2">
          SuzumeSports pioneers the creation of performance-driven custom
          sportswear and activewear, serving as a trusted manufacturer and
          exporter committed to elevating athletes' performance worldwide.
        </p>
      </div>

      {/* //casual wear products */}

      <div className="services-container mb-8 m-14 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6">
        {casualwearData.map((service, index) => (
          <ProductsBox
            key={index}
            title={service.title}
            content={service.content}
            image={service.image}
          />
        ))}
      </div>
    </>
  );
};

export default Casualwear;
