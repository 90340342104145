import React from "react";
import Hero from "./Hero";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Business from "./Business";
// import Hero from "./Hero";
const Home = () => {
  return (
    <div>
      
      <Hero />
      <Business />
    </div>
  );
};

export default Home;
