import {
  facebook,
  instagram,
  linkedin,
  paperplane,
  listcheck,
  lock,
  chart,
  workflow1,
  workflow2,
  workflow4,
  workflow5,
  workflow6,
  sports1,
  sports2,
  sports3,
  sports4,
  sports5,
  sports10,
  sports6,
  sports7,
  sports8,
  sports9,
  active1,
  active3,
  active2,
  active4,
  active5,
  active6,
  active7,
  active8,
  casual1,
  casual2,
  casual3,
  casual4,
  casual5,
  casual6,
} from "../assets";
//for services
export const servicesData = [
  {
    title: "Pattern Making",
    content:
      "Patterns are designed according to the required sizes and designs. Each product has a different pattern, designed with accuracy and precision.",
    image: workflow1,
  },

  {
    title: "Pre-Production Sample",
    content:
      "Before bulk production, we get your approval by making a sample and shipping it to you. Once approved, we move to the next step.",
    image: workflow2,
  },
  {
    title: "Material & Fabric Sourcing",
    content:
      "Fabric is sourced from the most reliable and trusted fabric suppliers. The fabric is stored in the fabric store in racks, and fabric inspection is done before shifting it to the cutting department.",
    image: workflow4,
  },
  {
    title: "Cutting & Printing Process",
    content:
      "After sourcing, the fabric is cut down into pieces according to the patterns. After that, according to our client's requirements, these pieces have printing or embroidery.",
    image: workflow4,
  },
  {
    title: "Sewing & Quality Check",
    content:
      "Cut panels are sewn together, and the design finally comes to life. After that, all items are carefully inspected to ensure the highest quality standards, which helps us to remain one of the best sportswear and activewear manufacturers.",
    image: workflow5,
  },
  {
    title: "Packing & Delivery",
    content:
      "After that, all items get pressed, tags are installed, and packed in poly bags. Finally, all items get packed in cartons and shipped using reliable delivery services.",
    image: workflow6,
  },
  // {
  //   title: "Clinical Evaluation and Evidence Generation",
  //   content:
  //     "Our team assists in designing and implementing clinical evaluation strategies, including clinical studies, literature reviews, and post-market surveillance activities. We ensure the generation of robust clinical evidence to support the safety and efficacy of your medical devices, meeting regulatory requirements and addressing the needs of stakeholders.",
  //   image: bg10,
  // },
  // {
  //   title: "Post-Market Surveillance and Vigilance",
  //   content:
  //     "We help establish post-market surveillance systems, including complaint handling, adverse event reporting, and trend analysis. Our experts assist in developing post-market surveillance strategies and support the preparation of post-market reports to ensure ongoing compliance, patient safety, and proactive risk management.",
  //   image: bg6,
  // },
  // {
  //   title: "Regulatory Compliance Training",
  //   content:
  //     "We offer customized training programs on medical device regulations, including FDA requirements, EU MDR, and international standards. Our interactive training sessions help your team gain a comprehensive understanding of regulatory compliance, enhancing their capabilities in navigating the regulatory landscape effectively.",
  //   image: bg7,
  // },
  // {
  //   title: "Regulatory Intelligence and Updates",
  //   content:
  //     "We keep you informed about the latest regulatory changes, industry trends, and emerging requirements. Our regulatory intelligence services provide timely updates, ensuring you stay ahead of evolving regulations and proactively adapt your strategies to maintain compliance and market competitiveness.",
  //   image: bg8,
  // },
  // {
  //   title: "International Market Expansion",
  //   content:
  //     "We support your expansion into new international markets by conducting regulatory assessments, assisting with localization requirements, and guiding you through the regional registration and approval processes. Our global expertise helps you navigate diverse regulatory landscapes and unlock new business opportunities.",
  //   image: bg9,
  // },
  // {
  //   title: "Regulatory Compliance Gap Analysis and Remediation",
  //   content:
  //     "Our team conducts comprehensive regulatory compliance gap assessments to identify areas of improvement and non-compliance. We provide detailed remediation plans, guiding you through corrective actions to ensure your products and processes meet all regulatory requirements.",
  //   image: bg2,
  // },
  // {
  //   title: "European Authorized Representative (EC Rep) Services",
  //   content:
  //     "For medical device manufacturers outside the European Union (EU), we offer comprehensive EC Rep services. As your authorized representative, we ensure compliance with the EU MDR and assist in fulfilling the legal obligations related to product registration, post-market surveillance, and interaction with competent authorities within the EU.",
  //   image: bg10,
  // },
  // {
  //   title: "Person Responsible for Regulatory Compliance (PRRC) Services",
  //   content:
  //     "Under the EU MDR, medical device manufacturers must designate a Person Responsible for Regulatory Compliance (PRRC). We provide qualified professionals who serve as your PRRC, ensuring adherence to regulatory requirements, overseeing conformity assessments, and facilitating communication with authorities, allowing you to meet the regulatory obligations effectively.",
  //   image: background3,
  // },
];
export const ServiceBox = ({ title, content, image }) => (
  <div className="service-box p-6 shadow-lg rounded-lg bg-white hover:scale-105  hover:shadow-xl transition-all duration-300 cursor-pointer">
    <div className="relative overflow-hidden rounded-lg">
      <img
        src={image}
        alt={title}
        className="w-full h-auto transform hover:scale-110"
      />
    </div>
    <div className="mt-4">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{content}</p>
    </div>
  </div>
);
//sportswear data
export const sportswearData = [
  {
    title: "American Football Uniforms",
    content:
      "We are one of the best sportswear manufacturer and exporter, We can manufacture American football uniforms in any size and design for both men and women.",
    image: sports1,
  },
  {
    title: "Baseball Uniforms",
    content:
      "We make premium quality uniforms & jerseys for basketball in all sizes for both men and women, share your requirements with us and we will manufacture them for you.",
    image: sports2,
  },
  {
    title: "Basketball Uniforms",
    content:
      "We are the leading provider of basketball uniforms, including jerseys and shorts. We serve customers ranging from youth to professional teams which play in leagues & tournaments.",
    image: sports3,
  },
  {
    title: "Soccer / Football Uniforms",
    content:
      "We are one of the best sportswear manufacturer, supplier & exporter, We can manufacture custom soccer uniform & football uniform in any size and design for both men and women.",
    image: sports4,
  },
  {
    title: "Rugby Uniforms",
    content:
      "You are in the right place for rugby uniforms and jerseys, custom warm-ups, team kits, and much more. We have everything you need to look stunning on the pitch.",
    image: sports5,
  },
  {
    title: "Ice Hockey Uniforms",
    content:
      "We make high-performance custom hockey jerseys & uniforms for professional teams and clubs. We manufacture custom-made uniforms that provide comfort and are durable as well.",
    image: sports6,
  },
  {
    title: "Golf Clothing",
    content:
      "We are one of the best sportswear manufacturer and exporter, We can manufacture customized golf clothing in any size and design for both men and women.",
    image: sports7,
  },
  {
    title: "Tennis Clothing",
    content:
      "We manufacture premium quality tennis clothing at competitive prices of any size for both men and women, share your requirements with us and we will manufacture them for you.",
    image: sports8,
  },
  {
    title: "Cycling Clothing",
    content:
      "We are the manufacturer of premium quality cycling clothing with a lot of customization options available which includes a very vast collection of colors, designs, and features.",
    image: sports9,
  },
  {
    title: "Cricket Uniforms",
    content:
      "We make premium quality cricket uniforms at competitive prices of any size for both men and women, share your requirements with us and we will take care of the rest.",
    image: sports10,
  },
];
//productsbox
export const ProductsBox = ({ title, content, image }) => (
  <div className="service-box p-6 sm:p-8 lg:p-12 shadow-lg rounded-lg bg-white hover:scale-105 hover:shadow-xl transition-all duration-300 flex flex-col md:flex-row lg:flex-row items-center w-full cursor-pointer">
    <div className="relative overflow-hidden rounded-full mb-4 sm:mb-0 md:mr-6 lg:mr-12 w-48 h-48 min-w-[12rem] min-h-[12rem]">
      <img
        src={image}
        alt={title}
        className="w-full h-full object-cover transform hover:scale-110 transition-all duration-300"
      />
    </div>
    <div className="flex-1 text-center md:text-left overflow-hidden">
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      <p className="text-gray-600 text-base">{content}</p>
    </div>
  </div>
);
//activeswear data
export const activewearData = [
  {
    title: "Sports Bras",
    content:
      "We are one of the best activewear manufacturer and exporter for sports bras and yoga wear. We can manufacture customized sports bras in any size for young girls and adults.",
    image: active1,
  },
  {
    title: "Leggings & Tights",
    content:
      "Our leggings and tights are absolutely your best workout companion & are made of highly elastic and breathable fabrics that guarantee you flawless performance in the gym.",
    image: active2,
  },

  {
    title: "Pants & Joggers",
    content:
      "Our pants and joggers are made to provide extreme comfort along with durability whenever you go for a run or for a workout. Share your design with us and we will take care of the rest.",
    image: active3,
  },
  {
    title: "Rash Guards",
    content:
      "We’re a top manufacturer of custom rash guards for men and women, offering wholesale pricing. Our products are made with highly breathable fabrics, providing flawless performance during gym workouts and other activities.",
    image: active4,
  },
  {
    title: "Tank Tops",
    content:
      "We’re a top activewear manufacturer and exporter, offering premium quality custom tank tops for both men and women. Our expertly crafted custom tank tops are made from high-quality materials, ensuring both comfort and durability.",
    image: active5,
  },
  {
    title: "Shorts",
    content:
      "Our shorts are made to provide extreme comfort along with durability whenever you go for a run or for a workout. We manufacture premium quality shorts for both men and women.",
    image: active6,
  },
  {
    title: "Compression Shirt",
    content:
      "We’re a top manufacturer of custom rash guards for men and women, offering wholesale pricing. Our products are made with highly breathable fabrics, providing flawless performance during gym workouts and other activities.",
    image: active7,
  },
  {
    title: "Yoga Pants",
    content:
      "Our pants and joggers are made to provide extreme comfort along with durability whenever you go for a run or for a workout. Share your design with us and we will take care of the rest.",
    image: active8,
  },
];
//casual wear data
export const casualwearData = [
  {
    title: "Hoodies",
    content:
      "Our custom hoodies & wholesale custom tracksuits are made to provide extreme comfort along with durability whenever you go for a run or for a workout. Share your design with us and we will take care of the rest.",
    image: casual1,
  },
  {
    title: "TrackSuits",
    content:
      "We manufacture premium quality custom jackets, custom jerseys, and custom tracksuits at competitive prices of any size for both men and women, share your requirements with us and we will manufacture them for you.",
    image: casual2,
  },
  {
    title: " T-Shirts",
    content:
      "We’re a top manufacturer of custom t-shirts for men and women, offering wholesale pricing. Our products are made with highly breathable fabrics, providing flawless performance during gym workouts and other activities.",
    image: casual3,
  },
  {
    title: "Polo Shirts",
    content:
      "We are a leading manufacturer and exporter of high-quality polo shirts. Our polo shirts are crafted to offer a perfect blend of style, comfort, and durability, making them suitable for both casual and semi-formal wear.",
    image: casual4,
  },
  {
    title: "SweatShirt",
    content:
      "We manufacture premium quality sweatshirts and uppers at competitive prices of any size for both men and women, share your requirements with us and we will manufacture them for you. ",
    image: casual5,
  },
  {
    title: "Jackets",
    content:
      "We manufacture premium quality custom jackets and custom jerseys at competitive prices of any size for both men and women, share your requirements with us and we will manufacture them for you.",
    image: casual6,
  },
];

//navbar
export const navLinks = [
  {
    id: "/",
    title: "HOME",
  },
  {
    id: "/sportswear",
    title: "SPORTSWEAR",
  },
  {
    id: "/activewear",
    title: "ACTIVEWEAR",
  },
  {
    id: "/casualwear",
    title: "CASUALWEAR",
  },

  // {
  //   id: "/Services",
  //   title: "SERVICES",
  // },
  {
    id: "/About",
    title: "ABOUT US",
  },
  {
    id: "/contact",
    title: "CONTACT US",
  },
];

export const footerLinks = [
  // {
  //   id: "/",
  //   title: "HOME",
  // },
  {
    id: "/sportswear",
    title: "SPORTSWEAR",
  },
  {
    id: "/activewear",
    title: "ACTIVEWEAR",
  },
  {
    id: "/casualwear",
    title: "CASUALWEAR",
  },

  // {
  //   id: "/Services",
  //   title: "SERVICES",
  // },
  {
    id: "/About",
    title: "ABOUT US",
  },
  {
    id: "/contact",
    title: "CONTACT US",
  },
  // // {
  // //   title: "Useful Links",
  // //   links: [
  // //     {
  // //       name: "Content",
  // //       link: "https://www.hoobank.com/content/",
  // //     },
  // //     {
  // //       name: "How it Works",
  // //       link: "https://www.hoobank.com/how-it-works/",
  // //     },
  // //     {
  // //       name: "Create",
  // //       link: "https://www.hoobank.com/create/",
  // //     },
  // //     {
  // //       name: "Explore",
  // //       link: "https://www.hoobank.com/explore/",
  // //     },
  // //     {
  // //       name: "Terms & Services",
  // //       link: "https://www.hoobank.com/terms-and-services/",
  // //     },
  // //   ],
  // },
  // {
  // title: "",
  // links: [
  //   {
  //     name: "Our services",
  //     link: "https://www.mebiocon.com//",
  //   },
  //   {
  //     name: "About",
  //     link: "https://www.mebiocon.com//",
  //   },
  //   {
  //     name: "Contact",
  //     link: "https://www.mebiocon.com//",
  //   },
  // {
  //   name: "Blog",
  //   link: "https://www.hoobank.com/blog/",
  // },
  // {
  //   name: "Newsletters",
  //   link: "https://www.hoobank.com/newsletters/",
  // },
  // ],
  // },
];
export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/mebiocon/?igshid=MzNlNGNkZWQ4Mg%3D%3D",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/mebiocon",
  },

  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];
export const features = [
  {
    id: "feature-1",
    icon: chart,
    title: "Client Care",
    content: "Your satisfaction is our utmost priority every step of the way.",
  },

  {
    id: "feature-2",
    icon: lock,
    title: "Quality Focus",
    content:
      "We aim to provide the best quality as per our customer expectations.",
  },
  {
    id: "feature-3",
    icon: paperplane,
    title: "Worldwide Shipping",
    content: "We offer worldwide shipping using top services like DHL / Fedex.",
  },
  {
    id: "feature-4",
    icon: listcheck,
    title: "Best Prices",
    content:
      "We offer premium products at the lowest price as per our customer expectations.",
  },
];

// export const feedback = [
//   {
//     id: "feedback-1",
//     content:
//       "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
//     name: "Herman Jensen",
//     title: "Founder & Leader",
//     img: people01,
//   },
//   {
//     id: "feedback-2",
//     content:
//       "Money makes your life easier. If you're lucky to have it, you're lucky.",
//     name: "Steve Mark",
//     title: "Founder & Leader",
//     img: people02,
//   },
//   {
//     id: "feedback-3",
//     content:
//       "It is usually people in the money business, finance, and international trade that are really rich.",
//     name: "Kenn Gallagher",
//     title: "Founder & Leader",
//     img: people03,
//   },
// ];

// export const stats = [
//   {
//     id: "stats-1",
//     title: "User Active",
//     value: "3800+",
//   },
//   {
//     id: "stats-2",
//     title: "Trusted by Company",
//     value: "230+",
//   },
//   {
//     id: "stats-3",
//     title: "Transaction",
//     value: "$230M+",
//   },
// ];

// export const clients = [
//   {
//     id: "client-1",
//     logo: airbnb,
//   },
//   {
//     id: "client-2",
//     logo: binance,
//   },
//   {
//     id: "client-3",
//     logo: coinbase,
//   },
//   {
//     id: "client-4",
//     logo: dropbox,
//   },
// ];
