import styles from "../style";
import {
  discount,
  homepic,
  background1,
  background3,
  background2,
  homeimage1,
  homeimage2,
  homeimage3,
  homeimage4,
} from "../assets";
import { servicesData, ServiceBox } from "../constants";
import GetStarted from "./GetStarted";
import Slider from "./Slider";
const images = [
  homeimage2,
  homeimage1,
  homeimage3,
  homeimage4,
  // Add more image URLs as needed
];
const texts = [
  "lorem ajsjaalllllllllllllllllllllllslorem ajsjaallllllllllllllllllllllls",
  "Text 2lorem ajsjaalllllllllllllllllllllllslorem ajsjaallllllllllllllllllllllls",
  "Text 3",
  // Add more texts as needed
];
const slides = [
  {
    image: homeimage3,
    title: "Custom Sportswear",
    highlight: "Manufacturer From",
    subtitle: "Sialkot, Pakistan",
    description: "Premium custom clothing for every active lifestyle.",
  },
  {
    image: homeimage2,
    title: "Custom ",
    highlight: "Activewear ",
    subtitle: "Manufacturer ",
    description: "Premium custom clothing for every active lifestyle.",
  },
  {
    image: homeimage1,
    title: "Custom Sportswear",
    highlight: "Manufacturer From",
    subtitle: "Sialkot Pakistan",
    description: "Premium custom clothing for every active lifestyle.",
  },

  {
    image: homeimage4,
    title: "Custom ",
    highlight: "Activewear ",
    subtitle: "Manufacturer ",
    description: "Premium custom clothing for every active lifestyle.",
  },
  // Add more slides as needed
];
const Hero = () => {
  return (
    <>
      <div>
        <Slider slides={slides} />
      </div>
      <div
        className={`bg-white flex-col      sm:py-5 py-0 ${styles.flexStart} `}
      >
        <div className="flex flex-col pt-2 mt-2  ">
          <h2 className="text-2xl text-center mt-0 sm:mt-15 font-poopins sm:text-4xl font-bold text-black">
            Our Vision
          </h2>
          <p className="text-base text-center font-poopins pl-7 pr-7 ml-5 mr-5 text-[18px] sm:text-[28px]  leading-[20px] sm:leading-[40px] mt-2">
            SuzumeSports pioneers the creation of performance-driven custom
            sportswear and activewear, serving as a trusted manufacturer and
            exporter committed to elevating athletes' performance worldwide.
          </p>
        </div>

        {/* <div className={`${styles.boxWidth} mt-10`}>
          <section
            id="home"
            className={`flex md:flex-row flex-col ${styles.paddingY}`}
          >
            <div
              className={`flex-1 ${styles.flexStart} text-center pl-4 ml-4 flex-col xl:px-0 sm:px-4 px-4`}
            >
              <div className="flex  flex-col justify-between items-center w-full">
                <h1 className="font-poppins font-semibold sm:text-[58px] text-[30px] text-black ss:leading-[70.8px] leading-[30px] ">
                  Your Partner for <br className="sm:block hidden" />{" "}
                  <span className="text-teal-400  sm:text-[68px] text-[30px] ss:leading-[70.8px] leading-[30px]">
                    Technical
                  </span>{" "}
                </h1>
                <h1 className="font-poppins  font-semibold ss:text-[58px] text-[30px] text-black ss:leading-[70.8px] leading-[30px] ">
                  Documentation
                </h1>{" "}
                <p className="font-poppins pb-2 text-black font-normal  text-[15px] sm:text-[18px]  leading-[18.8px] ss:leading-[30.8px]  text-black max-w-[470px] mt-2">
                  Full Technical Documentation (STED) services and the
                  Industry’s Fastest document compilation for EU MDR. Ace your
                  audits, maximize your team’s time and budget.
                </p>
              </div>
            </div>

            <div className="md:w-1/2">
              <img
                src={homepic}
                alt="Image"
                className="md:max-w-full rounded-lg shadow-2xl"
              />
            </div>
          </section>
        </div> */}
      </div>

      {/* services */}

      {/* <div className="flex flex-col ">
        <h2 className="text-2xl  text-center  font-poopins sm:text-4xl font-bold text-teal-600">
          Our Services
        </h2>
      </div>

      <div className="services-container m-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {servicesData.map((service, index) => (
          <ServiceBox
            key={index}
            title={service.title}
            content={service.content}
            image={service.image}
          />
        ))}
      </div> */}
    </>
  );
};

export default Hero;
