import { features } from "../constants";
import styles, { layout } from "../style";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { servicesData, ServiceBox } from "../constants";
import Button from "./Button";
import {
  homeimage3
  
} from "../assets";
const Business = () => (
  <>
    <div className={`bg-white sm:px-16 px-6 ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <section id="features" className={layout.section}>
          <div className={layout.sectionInfo}>
            <h2 className={styles.heading2}>
              Premium custom clothing,
              <br className="sm:block hidden" />
              for every active lifestyle.
            </h2>
            <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
              Experience the advantage of low minimum order quantities and swift
              lead times. Our rigorous quality control measures guarantee
              customer satisfaction. Streamline your sportswear and activewear
              production with us – once your requirements are finalized, we move
              swiftly into production.
            </p>

            <Link to="/Contact">
              <button
                type="button"
                className={`py-4 px-6 mt-10 font-poppins font-medium text-white text-[18px] hover:bg-red-900 bg-black rounded-[10px] outline-none ${styles}`}
              >
                Get In Touch
              </button>
            </Link>
          </div>

          <div className={`${layout.sectionImg} flex-col`}>
            {features.map((feature, index) => (
              <FeatureCard key={feature.id} {...feature} index={index} />
            ))}
          </div>
        </section>
      </div>
    </div>
    {/* //small cards/boxes */}
    {/* Workflow */}
    <div className="flex flex-col ">
      <h2 className="text-2xl  text-center  font-poopins sm:text-4xl font-bold text-black">
        Our Workflow
      </h2>
    </div>
    <div className="services-container m-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {servicesData.map((service, index) => (
        <ServiceBox
          key={index}
          title={service.title}
          content={service.content}
          image={service.image}
        />
      ))}
    </div>
    {/* a picture and some text */}
    <div className="mt-10 flex flex-col md:flex-row items-center justify-center pb-4">
      <div className="md:w-1/2">
        <h1 className="font-poppins pl-4 text-4xl font-bold mb-4 text-black ml-4">
          Why Us?
        </h1>
        <p className="font-poppins p-5 text-[10px] sm:text-[16px] text-black text-justify mb-6 m-4">
          We are dedicated to continuous improvement, aiming to deliver premium
          quality products and exceptional customer service. Our goal is to
          achieve 100% customer satisfaction, solidifying our position as one of
          the leading manufacturers, suppliers, and exporters of sportswear,
          activewear, and fitness apparel. Strict Quality Control, 100%
          Satisfaction Guaranteed. Highly Professional Staff, Accurate Testing
          Processes. Unrivalled Workmanship, Professional and Qualified.
        </p>
      </div>
      <div className="md:w-1/2 w-full">
        <img
          src={homeimage3}
          alt="Image"
          className="md:max-w-full mt-4 rounded-lg shadow-2xl"
        />
      </div>
    </div>
  </>
);

const FeatureCard = ({ icon, title, content, index }) => (
  <div
    className={`flex flex-row p-6 rounded-[20px] ${
      index !== features.length - 1 ? "mb-6" : "mb-0"
    } bg-gray-100 hover:bg-gray-200`}
  >
    <div
      className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-white`}
    >
      <img src={icon} alt="star" className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-poppins font-semibold text-black text-[18px] leading-[23.4px] mb-1">
        {title}
      </h4>
      <p className="font-poppins font-normal text-black text-[16px] leading-[24px]">
        {content}
      </p>
    </div>
  </div>
);

export default Business;
